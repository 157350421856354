<template>
  <!-- 人员异常 -->
  <div class="box-card" style="text-align: left">
    <!-- 查询表单 -->
    <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-row>
          <el-col :span="24">
            <el-form-item label="组织：" class="organization_box">
              <el-cascader popper-class="my-cascader" filterable v-model="queryForm.organization_id" clearable
                checkStrictly :props="defaultProps" placeholder="请选择组织..." :options="organizationSeleteList"
                @change="onSubmit()">
              </el-cascader>
            </el-form-item>
            <el-form-item label="设备名称：">
              <el-select popper-class="my-select" filterable clearable v-model="queryForm.facesluice_id"
                placeholder="请选择设备" @clear="queryForm.facesluice_id = null" @change="onSubmit()">
                <!-- <el-option v-for="item in faceDeviceList" :key="item._id" :label="item.device_face_name" :value="item._id"> -->
                <el-option v-for="item in faceDeviceList" :key="item._id" :label="item.device_face_name"
                  :value="item.device_face_serial">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="起止时间：">
              <el-date-picker size="small" v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="姓名：">
              <el-input maxlength="15" v-model="queryForm.person_name" @keyup.enter.native="onSubmit()"
                placeholder="请输入姓名" @input="(e) => (queryForm.person_name = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item v-if="!isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isMultiRow">
          <el-col :span="24">
            <el-form-item label="核验类型：">
              <el-select popper-class="my-select" filterable clearable v-model="queryForm.verification_type"
                placeholder="请选择核验类型" @clear="queryForm.verification_type = null" @change="onSubmit()">
                <el-option v-for="item in getOptions('VerificationType')" :key="item.value" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </lebo-query>
    <le-card title="人员异常">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="warning" icon="daochu" @click="pullFile" v-if="isShowBtn('631998e1cc7f0000ae0058da')">导出
          </lbButton>
        </div>
      </template>
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="pic">
            <el-image fit="contain" :src="row.pic" :preview-src-list="[row.pic]" v-if="row.pic"></el-image>
            <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
              :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else></el-image>
          </template>
          <template slot-scope="{row}" slot="register_pic">
            <el-image fit="contain" :src="row.register_pic" :preview-src-list="[row.register_pic]"
              v-if="row.register_pic"></el-image>
            <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
              :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else>
            </el-image>
          </template>
          <template slot-scope="{row}" slot="organize_name">
            <span>{{ row.organize_name ? row.organize_name : '无' }} </span>
          </template>
          <template slot-scope="{row}" slot="per_type">
            <span>{{ getWayName("PersonType", row.per_type) }}
            </span>
          </template>
          <template slot-scope="{row}" slot="verification_type">
            <span>{{ getWayName("VerificationType", row.verification_type) }} </span>
          </template>
          <template slot-scope="{row}" slot="exit_type">
            <span>{{ getWayName("FaceExitType", row.exit_type) }} </span>
          </template>
          <template slot-scope="{row}" slot="go_time">
            {{ $moment(row.go_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookPersonWaring(row._id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe height="565">
          <el-table-column label="现场图片" width="100">
            <template slot-scope="scope">
              <el-image fit="contain" :src="scope.row.pic" :preview-src-list="[scope.row.pic]"
                v-if="scope.row.pic"></el-image>
              <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
                :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else></el-image>
            </template>
          </el-table-column>
          <el-table-column label="注册图片" width="100">
            <template slot-scope="scope">
              <el-image fit="contain" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]"
                v-if="scope.row.register_pic"></el-image>
              <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
                :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="person_name" label="姓名"></el-table-column>
          <el-table-column prop="organize_name" label="所属组织" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.organize_name ? scope.row.organize_name : '无' }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="facesluice_name" label="设备名称"></el-table-column>
          <el-table-column prop="per_type" label="人员身份">
            <template slot-scope="scope">
              <span>{{ getWayName("PersonType", scope.row.per_type) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="verification_type" label="核验类型">
            <template slot-scope="scope">
              <span>{{ getWayName("VerificationType", scope.row.verification_type) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="exit_type" label="出入类型">
            <template slot-scope="scope">
              <span>{{ getWayName("FaceExitType", scope.row.exit_type) }} </span>
            </template>
          </el-table-column>
          <el-table-column label="出入时间" width="140">
            <template slot-scope="scope">
              {{ $moment(scope.row.go_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookPersonWaring(scope.row._id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看人员异常-->
    <lebo-dialog append-to-body title="查看人员异常" :isShow="showLookPersonWaring" width="50%"
      @close="showLookPersonWaring = false" footerSlot closeOnClickModal>
      <lookPersonWaring :id="dialogAccessRecordsId" v-if="showLookPersonWaring" @closeDialog="closeAccessRecordsId">
      </lookPersonWaring>
    </lebo-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getPersonRecordPageList, getPersonRecordDetailt, getFaceDeviceList, getOrganizationSeleteList } from '@/api/securityApi'
import { getOrganizatioTreeList } from '@/api/administrative'
// import { getCaptureRecordList } from '../../api/administrative';
import lookPersonWaring from './components/lookPersonWaring/index.vue'
export default {
  components: { lookPersonWaring },
  data () {
    return {
      organizationSeleteList: [], // 组织列表下拉列
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        // multiple: true,可以选择多个选项
        checkStrictly: true // 可以选择父类名称进行选择
      },
      faceDeviceList: [], // 人脸设备下拉列表
      timeduan: '',
      // 查询表单
      queryForm: {
        organization_id: [],
        facesluice_id: '',
        go_begintime: '',
        go_endtime: '',
        person_name: '',
        verification_type: null,
        per_type: 3,
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      TotalCount: null,
      showLookPersonWaring: false,
      dialogAccessRecordsId: '',
      isMultiRow: true, // 查询下拉框
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '现场图片',
            prop: 'pic',
            slot: true
          }, {
            label: '注册图片',
            prop: 'register_pic',
            slot: true
          }, {
            label: '姓名',
            prop: 'person_name'
          }, {
            label: '所属组织',
            prop: 'organize_name',
            slot: true
          }, {
            label: '设备名称',
            prop: 'facesluice_name'
          }, {
            label: '人员身份',
            prop: 'per_type',
            slot: true
          }, {
            label: '核验类型',
            prop: 'verification_type',
            slot: true
          }, {
            label: '出入类型',
            prop: 'exit_type',
            slot: true
          }, {
            label: '出入时间',
            prop: 'go_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetOrganizationSeleteList()
    this.fnGetFaceDeviceList()
    this.fnGetPersonRecordPageList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'isAdmin', 'getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.go_begintime = newV[0]
        this.queryForm.go_endtime = newV[1]
      } else {
        this.queryForm.go_begintime = ''
        this.queryForm.go_endtime = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取组织列表下拉列
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizatioTreeList()
      this.organizationSeleteList = res && res.Code === 200 ? this.filterChildren(res.Data.concat()) : []
    },
    // 获取设备下拉列表
    async fnGetFaceDeviceList () {
      const res = await getFaceDeviceList()
      this.faceDeviceList = res && res.Code === 200 ? res.Data : []
    },
    // 获取人员通行记录列表
    async fnGetPersonRecordPageList () {
      const res = await getPersonRecordPageList({
        organization_id: this.queryForm.organization_id && this.queryForm.organization_id.length > 0 ? this.queryForm.organization_id[this.queryForm.organization_id.length - 1] : '',
        facesluice_id: this.queryForm.facesluice_id,
        go_begintime: this.queryForm.go_begintime,
        go_endtime: this.queryForm.go_endtime,
        person_name: this.queryForm.person_name,
        verification_type: this.queryForm.verification_type ? this.queryForm.verification_type : null,
        per_type: this.queryForm.per_type ? this.queryForm.per_type : 3,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        is_export: false
      })
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.TotalCount = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetPersonRecordPageList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetPersonRecordPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPersonRecordPageList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetPersonRecordPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPersonRecordPageList()
    // },
    // 导出数据
    async pullFile () {
      // if (this.queryForm.organization_id.length <= 0) return this.$msg.info('请选择导出数据的组织');
      if (!this.queryForm.go_begintime && !this.queryForm.go_endtime) return this.$msg.info('请选择导出数据的时间')
      const res = await getPersonRecordPageList({
        PageSize: 0,
        PageIndex: 0,
        organization_id: this.queryForm.organization_id.length > 0 ? this.queryForm.organization_id[this.queryForm.organization_id.length - 1] : '',
        facesluice_id: this.queryForm.facesluice_id,
        go_begintime: this.queryForm.go_begintime,
        go_endtime: this.queryForm.go_endtime,
        person_name: this.queryForm.person_name,
        verification_type: this.queryForm.verification_type ? this.queryForm.verification_type : null,
        per_type: this.queryForm.per_type ? this.queryForm.per_type : 3,
        is_export: true
      })
      if (res && res.Code === 200) {
        this.exportFile(res.Data.DataList)
      }
    },
    // 导出
    exportFile (tableData) {
      if (tableData.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '姓名',
            '所属组织',
            '设备名称',
            '人员身份',
            '核验类型',
            '出入类型',
            '出入时间'
          ]
          const filterVal = [
            'person_name',
            'organize_name',
            'facesluice_name',
            'per_type',
            'verification_type',
            'exit_type',
            'go_time'
          ]
          const list = tableData
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '人员通行记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          var map = {}
          this.$set(map, j, v[j])
          return this.formatter(map, { property: j })
          // 为formatter专门为了码表渲染的组件
        })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      switch (column.property) {
        case 'go_time':
          return this.$moment(row.go_time).format('YYYY-MM-DD HH:mm:ss')
        case 'per_type':
          return this.getWayName('PersonType', row.per_type)
        case 'verification_type':
          return this.getWayName('VerificationType', row.verification_type)
        case 'exit_type':
          return this.getWayName('FaceExitType', row.exit_type)
        default:
          return row[column.property]
      }
    },
    closeAccessRecordsId () {
      this.showLookPersonWaring = false
      this.fnGetPersonRecordPageList()
    },
    lookPersonWaring (id) {
      this.showLookPersonWaring = true
      this.dialogAccessRecordsId = id
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;

  .queryBox {
    margin-bottom: 15px;
  }
}

.organization_box {
  /deep/.el-input.el-input--small.el-input--suffix {
    width: 250px !important;
  }
}

.el-image {
  width: 60px;
  height: 60px;
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 530px;
  }
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 500px !important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 230px!important;
}
</style>
